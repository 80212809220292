
//** IMPORT GOOGLE FONTS */
    /* montserrat-regular - latin_latin-ext */
    @font-face {
        font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        src: url('../assets/fonts/montserrat-v25-latin_latin-ext-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    }
    /* montserrat-italic - latin_latin-ext */
    @font-face {
        font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
        font-family: 'Montserrat';
        font-style: italic;
        font-weight: 400;
        src: url('../assets/fonts/montserrat-v25-latin_latin-ext-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    }
    /* montserrat-700 - latin_latin-ext */
    @font-face {
        font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        src: url('../assets/fonts/montserrat-v25-latin_latin-ext-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    }
    /* montserrat-700italic - latin_latin-ext */
    @font-face {
        font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
        font-family: 'Montserrat';
        font-style: italic;
        font-weight: 700;
        src: url('../assets/fonts/montserrat-v25-latin_latin-ext-700italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    }
    /* montserrat-900 - latin_latin-ext */
    @font-face {
        font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        src: url('../assets/fonts/montserrat-v25-latin_latin-ext-900.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    }
    /* montserrat-900italic - latin_latin-ext */
    @font-face {
        font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
        font-family: 'Montserrat';
        font-style: italic;
        font-weight: 900;
        src: url('../assets/fonts/montserrat-v25-latin_latin-ext-900italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    }

//** Import variables */
    @import 'variables';

//**====== FONT STYLES =======*/

//** Global styles */

    body {
        font-family: 'Montserrat', sans-serif;
        color: $ghost-white;
        //!! font-display: swap; - add this only if you have to. Google fonts automaticaly adds &display=swap
    }

    h1 {
        font-weight: 900;
        text-transform: uppercase;
        font-size: $px48;
        line-height: 4rem;

        span {
            color: $blood-orange;
        }
    }

    h2 {
        font-size: $px31;
        text-transform: uppercase;
        font-weight: 900;
        line-height: 3rem;
    }

    h3 {
        font-size: $px28;
        line-height: 2.5rem;
    }

    h4 {
        font-size: $px24;
    }

    p, a, table, li {
        font-size: $px20;
    }

    main {
        p {
            line-height: 2.5rem;
            word-spacing: 2px;
        }
    }

//**====== SPECIAL CASES ======*/

.nav-links li a { //!!! Change font size on mobil
    font-weight: 700;
}

.video-text {
    font-weight: 900;
}

.product-filter {
    
    label, select {
        font-weight: 700;
        font-size: $px20; 
    }
}
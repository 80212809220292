
//** COLOR PALLETE */

    $night-rider: #1b1b1b;
    $ghost-white : #f8f8ff;
    $blood-orange : #FD9C01;

//** PADDINGs AND MARGINs */

    $standart-padding: 6rem;
    $standart-padding-mobile: 2.5rem;
    $main-top-padding: 8rem;

//** Media querie breakpoints */

    $mobile-breakpoint: 425px;  //* All screens untill this */
    $desktop-breakpoint: 426px; //* All screens starting from this and beyound */

//** RESPONSIVE FONT SIZES */

$px14: 0.875rem;
$px15: 0.9375rem;
$px16: 1rem;
$px17: 1.0625rem;
$px18: 1.125rem;
$px19: 1.1875rem;
$px20: 1.25rem;
$px21: 1.3125rem;
$px22: 1.375rem;
$px23: 1.4375rem;
$px24: 1.5rem;
$px25: 1.5625rem;
$px26: 1.65rem;
$px27: 1.6875rem;
$px28: 1.8rem;
$px29: 1.8125rem;
$px30: 1.875rem;
$px31: 1.9375rem;
$px32: 2rem;
$px33: 2.0625rem;
$px34: 2.125rem;
$px35: 2.1875rem;
$px36: 2.25rem;
$px38: 2.375rem;
$px42: 2.625rem;
$px45: 2.8125rem;
$px48: 3rem;
